
footer.customerFooter 
{
    display: flex;
    justify-content: center;
    padding: 4px 0;
    background-color: #66cccc;
    position: fixed;
    bottom: 0;
    width: 100%;

    p
    {
        margin: 0;
        color: #000000;
        text-align: center;

        span
        {
            color: #fff;
            display: inline-block;
        }
    }

    a
    {
        color: #000000;
        text-decoration: none;
        font-size: 16px;

        &:hover
        {
            text-decoration: underline;
        }
    }
}

.text-white
{
    color: #ffffff;
}

.social-media
{
    display: flex;
    justify-content: flex-end;
    
    li
    {
        display: block;
        margin: 0 10px;
    }
}